import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#F7F7F6',
      neutralLight3: '#F0F0F0',
      neutralLight2: '#A2A9AD',
      neutralLight1: '#696969',
      neutralDark4: '#372030',
      neutralDark3: '#311E2B',
      neutralDark2: '#2B2B2B',
      neutralDark1: '#000000',
      primaryDark: '#412739',
      primaryDark2: '#3C2435',
      primaryLight: '#ED1C33',
    },
  },
  fontFamily: {
    heading: "'ivyora-display', sans-serif",
    paragraph: "'acumin-pro', serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
