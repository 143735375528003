import './src/theme/destyle.css'
import './src/theme/global.css'

import { ParallaxProvider } from 'react-scroll-parallax'

import { ThemeProvider } from './src/theme'

const transitionDelay = 1000

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider>
      <ParallaxProvider>{element}</ParallaxProvider>
    </ThemeProvider>
  )
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)

    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay,
    )
  }
  return false
}
